// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 퀴즈 네비게이션 바 */
.quiz-navbar {
    background-color: #f4f4f4; /* 배경색 */
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
  }
  
  .quiz-navbar-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .quiz-navbar-item {
    margin-right: 20px; /* 항목 사이의 간격 */
  }
  
  .quiz-navbar-item a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .quiz-navbar-item a.active,
  .quiz-navbar-item a:hover {
    background-color: #ddd; /* 호버 및 활성화 시 배경색 */
    color: #000;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/QuizNavbar.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,yBAAyB,EAAE,QAAQ;IACnC,aAAa;IACb,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB,EAAE,cAAc;EACpC;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;;IAEE,sBAAsB,EAAE,mBAAmB;IAC3C,WAAW;EACb","sourcesContent":["/* 퀴즈 네비게이션 바 */\n.quiz-navbar {\n    background-color: #f4f4f4; /* 배경색 */\n    padding: 10px;\n    border-radius: 5px;\n    margin: 10px;\n  }\n  \n  .quiz-navbar-list {\n    display: flex;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .quiz-navbar-item {\n    margin-right: 20px; /* 항목 사이의 간격 */\n  }\n  \n  .quiz-navbar-item a {\n    text-decoration: none;\n    color: #333;\n    font-size: 1rem;\n    padding: 8px 16px;\n    border-radius: 5px;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n  .quiz-navbar-item a.active,\n  .quiz-navbar-item a:hover {\n    background-color: #ddd; /* 호버 및 활성화 시 배경색 */\n    color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
