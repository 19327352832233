// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  background-color: #333; /* 배경색 */
  padding: 10px 20px; /* 상하 패딩, 좌우 패딩 */
}

.navbar-list {
  display: flex;
  list-style: none; /* 기본 리스트 스타일 제거 */
  margin: 0; /* 기본 마진 제거 */
  padding: 0; /* 기본 패딩 제거 */
}

.navbar-list li {
  margin-left: 20px; /* 각 메뉴 항목 사이의 간격 */
}

.navbar-list a {
  color: #ffffff; /* 링크 텍스트 색상 */
  text-decoration: none; /* 기본 밑줄 제거 */
  font-size: 1.2rem; /* 폰트 크기 */
  padding: 5px 10px; /* 패딩 */
  border-radius: 5px; /* 모서리 둥글게 */
  transition: background-color 0.3s, color 0.3s; /* 배경색과 텍스트 색상 전환 */
}

.navbar-list a:hover {
  background-color: #444; /* 호버 시 배경색 */
  color: #ffffff; /* 호버 시 텍스트 색상 */
}

.navbar-list a:active {
  background-color: #555; /* 클릭 시 배경색 */
}
`, "",{"version":3,"sources":["webpack://./src/css/Navbar.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB,EAAE,QAAQ;EAChC,kBAAkB,EAAE,iBAAiB;AACvC;;AAEA;EACE,aAAa;EACb,gBAAgB,EAAE,kBAAkB;EACpC,SAAS,EAAE,aAAa;EACxB,UAAU,EAAE,aAAa;AAC3B;;AAEA;EACE,iBAAiB,EAAE,mBAAmB;AACxC;;AAEA;EACE,cAAc,EAAE,cAAc;EAC9B,qBAAqB,EAAE,aAAa;EACpC,iBAAiB,EAAE,UAAU;EAC7B,iBAAiB,EAAE,OAAO;EAC1B,kBAAkB,EAAE,YAAY;EAChC,6CAA6C,EAAE,mBAAmB;AACpE;;AAEA;EACE,sBAAsB,EAAE,aAAa;EACrC,cAAc,EAAE,gBAAgB;AAClC;;AAEA;EACE,sBAAsB,EAAE,aAAa;AACvC","sourcesContent":["/* Navbar.css */\n\n.navbar {\n  display: flex;\n  align-items: center;\n  background-color: #333; /* 배경색 */\n  padding: 10px 20px; /* 상하 패딩, 좌우 패딩 */\n}\n\n.navbar-list {\n  display: flex;\n  list-style: none; /* 기본 리스트 스타일 제거 */\n  margin: 0; /* 기본 마진 제거 */\n  padding: 0; /* 기본 패딩 제거 */\n}\n\n.navbar-list li {\n  margin-left: 20px; /* 각 메뉴 항목 사이의 간격 */\n}\n\n.navbar-list a {\n  color: #ffffff; /* 링크 텍스트 색상 */\n  text-decoration: none; /* 기본 밑줄 제거 */\n  font-size: 1.2rem; /* 폰트 크기 */\n  padding: 5px 10px; /* 패딩 */\n  border-radius: 5px; /* 모서리 둥글게 */\n  transition: background-color 0.3s, color 0.3s; /* 배경색과 텍스트 색상 전환 */\n}\n\n.navbar-list a:hover {\n  background-color: #444; /* 호버 시 배경색 */\n  color: #ffffff; /* 호버 시 텍스트 색상 */\n}\n\n.navbar-list a:active {\n  background-color: #555; /* 클릭 시 배경색 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
